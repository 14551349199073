  .a {
    position: relative;
    color: #000;
    text-decoration: none;
  }
  
  .a:hover {
    color: #000;
  }
  
  .a::before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    bottom: 9px;
    left: 0;
    background-color: #000;
    transform: scaleX(0);
    transform-origin: top right;
    transition: transform 0.3s ease;
  }
  
  .a:hover::before {
    transform: scaleX(1);
  }